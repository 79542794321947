import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import cn from 'classnames';
import emailMask from 'text-mask-addons/dist/emailMask';
import { set, get, pickBy, isEmpty, omit } from 'lodash';
import Validator from 'components/Validator/Validator';
import Sidebar from 'components/Sidebar';
import LoanAmountRequested from 'components/LoanAmountRequested';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Checkbox from 'components/Form/Checkbox';

import { Button, VerifyButton, ButtonLink } from 'components/Button';
import {
  Col,
  Container,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Popup from 'components/Popup';
import PopoverCheckbox from 'components/Form/PopoverCheckbox';

import { verifyAddress } from 'utils/verifyAddress';
import { toTitleCase } from 'utils/toTitleCase';
import { unSupportedStates } from 'utils/unSupportedStates';
import { currencyMask, unmask, numberUnmask, floatUnmask } from 'utils/masks';
import { formatCurrency } from 'utils/formatCurrency';
import { parseUrlParams } from 'utils/parseUrlParams';
import { states } from 'utils/states';
import {
  fetchRentOrOwn,
  fetchEmploymentYears,
  fetchEmploymentStatus,
  getTestValuesData,
} from 'actions/lookup';
import { appConfig } from 'config/appConfig';

import { checkinAction, getAffDetails, checkDuplication } from 'actions/workflow';

import { MIN_LOAN_AMOUNT, MAX_LOAN_AMOUNT } from 'utils/loanAmounts';
import schema from './schema';
import { consentLabel } from './constant';

const params = parseUrlParams(window.location.search);
const businessTypeList = [
  { value: 'cosmeticsurgery', title: 'Cosmetic Surgery' },
  { value: 'debtconsolidation', title: 'Debt Consolidation' },
  { value: 'dental', title: 'Dental' },
  { value: 'funeral', title: 'Funeral' },
  { value: 'generalhealthcare', title: 'General Healthcare' },
  { value: 'homeimprovement', title: 'Home Improvement' },
  { value: 'majorpurchase', title: 'Major Purchase' },
];

const Application = (props) => {
  const { className, validator: { values, errors, isValid } } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [iovationBlackBox, setIovationBlackBox] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isAddressPopupVisible, setIsAddressPopupVisible] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [popupType, setPopupType] = useState(null);
  const [isVerifyButtonClicked, setIsVerifyButtonClicked] = useState(false);
  const [grossMonthlyIncomeDescription, setGrossMonthlyIncomeDescription] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorResponse, setErrorResponse] = useState({});
  const [employmentYearsData, setEmploymentYearsData] = useState(null);
  const [employmentStatusData, setEmploymentStatusData] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [selectedTestSubject, setSelectedTestSubject] = useState(0);
  const [testSubjects, setTestSubjects] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFormValidFromService, setIsFormValidFromService] = useState(true);
  const [prevaddress, setPrevaddress] = useState({});
  const [isEmploymentInfoRequired, setIsEmploymentInfoRequired] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isDupeRuleEnabled, setIsDupeRuleEnabled] = useState(false);
  const isFuneral = window.location.pathname === '/funeral';
  const form = useRef(null);

  useEffect(() => {
    if (!isEmploymentInfoRequired && values.financials && values.financials.stated && values.financials.stated.employmentYears) {
      const formData = {
        ...values,
        financials: {
          ...values.financials,
          stated: {
            ...values.financials.stated,
            employmentYears: '',
          },
        },
      };
      props.validator.setValues(formData);
    }
  }, [isEmploymentInfoRequired]);

  const getLookupData = () => {
    props.fetchEmploymentYears({
      url: '/lookup/employment-years',
      success: (res) => {
        setEmploymentYearsData(res);
      },
      fail: (err) => {
        console.log('err:', err);
      },
    });

    props.fetchEmploymentStatus({
      url: '/lookup/employment-status',
      success: (res) => {
        setEmploymentStatusData(res);
      },
      fail: (err) => {
        console.log('err:', err);
      },
    });
  };

  const loadHandleSetValues = () => {
    props.getTestValuesData({
      url: '/lookup/set-values-dtc-application',
      success: (res) => {
        const testList = {
          default: res.default.attributes,
          applications: res.applications.attributes.values,
        };
        setTestSubjects(testList);
      },
      fail: (err) => {
        console.log('Error', err);
      },
    });
  };

  const checkDupeRules = () => {
    props.checkDuplication({
      success: (res) => {
        setIsDupeRuleEnabled(res.checkDuplication);
      },
      fail: (err) => {
        console.log('Error', err);
      },
    });
  };

  useEffect(() => {
    if (window.IGLOO && window.IGLOO.getBlackbox) {
      const bbObj = window.IGLOO.getBlackbox();
      setIovationBlackBox(bbObj.blackbox);
    }
  }, [props.location]);

  useEffect(() => {
    const { validator: { setValues } } = props;
    if (appConfig.isSetValueVisible) {
      loadHandleSetValues();
      checkDupeRules();
    }
    const initialFormData = {
      requestedAmount: params.loanamount
        ? ((Number(params.loanamount) > MAX_LOAN_AMOUNT || Number(params.loanamount) < MIN_LOAN_AMOUNT)
          ? '1000.00'
          : params.loanamount)
        : '1000.00',
      purpose: params.prod,
      channel: {
        code: 'dtc',
        name: 'virtual',
      },
      applicant: {
        firstName: params.fname,
        lastName: params.lname,
        email: params.ename,
        address: {
          city: params.city,
          state: params.state,
          zipcode: params.sip,
        },
        phoneNumber: {
          number: params.phone,
        },
      },
    };
    setValues(initialFormData);

    // iovation
    window.io_global_object_name = 'IGLOO';
    window.IGLOO = window.IGLOO || {
      enable_flash: false,
      bb_callback: (bb) => {
        setIovationBlackBox(bb);
      },
      loader: {
        subkey: '',
        version: 'general5',
      },
    };
    const script = document.createElement('script');
    script.async = true;
    script.src = '/assets/js/iovation_loader_only.js';
    document.head.appendChild(script);
    // End iovation

    getLookupData();
    if (params.aid) {
      props.getAffDetails({
        url: `/workflows/dtc/affiliates/${params.aid}`,
        success: (res) => {
          if (res.purpose) setPurpose(res.purpose);
        },
        fail: (error) => {
          console.log('Error', error);
        },
      });
    }
  }, []);

  useEffect(() => {
    const { validator: { setValues } } = props;
    setValues({
      ...values,
      consentElectronicCommunication: values.allConsent,
      consentToTermsAndConditions: values.allConsent,
      consentToThirdParty: values.allConsent,
    });
  }, [values.allConsent]);

  useEffect(() => {
    const { validator: { setValues } } = props;
    if (values.consentElectronicCommunication && values.consentToTermsAndConditions) {
      setValues({
        ...values,
        allConsent: true,
      });
    }
  }, [values.consentElectronicCommunication, values.consentToTermsAndConditions]);

  const getCurrentDate = () => format(new Date(), 'MM/dd/yyyy');

  const handleSubmitForm = (data, e) => {
    e.preventDefault();
    const { validator: { validate }, ipAddress } = props;
    const isAddressValid = appConfig.smartyStreetEnforce ? isVerified : true;

    setIsSubmitted(true);
    setIsFormValidFromService(true);

    if (validate(isEmpty(purpose) && !isFuneral ? schema : omit(schema, 'purpose')).isValid && isAddressValid) {
      setIsLoading(true);

      const referral = pickBy({
        sourceIP: ipAddress,
        ppcCode: params.ppccode,
        pId: params.pid,
        lm: params.lm,
        aId: params.aid,
        utmSource: params.utm_source ? decodeURI(params.utm_source) : params.utm_source,
        utmMedium: params.utm_medium ? decodeURI(params.utm_medium) : params.utm_medium,
        utmCampaign: params.utm_campaign ? decodeURI(params.utm_campaign) : params.utm_campaign,
        sId: params.sid,
        url: window.location.href,
      }, (item) => item !== undefined);

      const formData = {
        ...data,
        ClientId: data && data.ClientId && data.ClientId.trim().replace(/\s\s+/g, ' '),
        consentToReceiveMessages: true,
        requestedAmount: floatUnmask(data.requestedAmount),
        applicant: {
          ...data.applicant,
          firstName: (data.applicant.firstName).trim(),
          lastName: (data.applicant.lastName).trim(),
          address: {
            ...data.applicant.address,
            country: 'USA',
          },
          phoneNumber: {
            Type: 'Mobile',
            Number: numberUnmask(data.applicant.phoneNumber.number),
          },
          ssn: numberUnmask(data.applicant.ssn),
          iovationBlackBox,
        },
        financials: {
          stated: {
            ...data.financials.stated,
            source: 'application',
            grossMonthlyIncome: unmask(data.financials.stated.grossMonthlyIncome),
            employerName: data.financials.stated.employerName ? (data.financials.stated.employerName).trim() : '',
          },
        },
        submittedDate: getCurrentDate(),
        referral,
      };

      if (!isEmpty(purpose)) {
        formData.purpose = get(purpose, 'code');
      }

      if (isFuneral) {
        set(formData, 'purpose', 'funeral');
      }

      props.checkinAction({
        data: formData,
        url: 'workflows/dtc/dtc1/start',
        success: (res) => {
          if (get(res, 'data.data.alternateDeclined')) {
            const pathData = res.data.url.split('?');
            props.history.push({
              pathname: pathData[0],
              search: pathData[1],
              state: {
                data: res.data.data,
              },
            });
          } else if (get(res, 'data.data.IsDuplicate')) {
            props.history.push({
              search: '',
              pathname: `/${props.match.params.workflowtype}/duplicate-declined`,
              state: {
                data: get(res, 'data.data'),
              },
            });
          } else {
            props.history.push(res.data.url);
          }
        },
        fail: (error) => {
          const errorsInternal = {};
          const errorsArr = get(error, 'data.failure.errors') || [];
          errorsArr.forEach((item) => {
            errorsInternal[get(item, 'subject')] = get(item, 'message');
          });
          setIsFormValidFromService(false);
          setIsLoading(false);
          setErrorResponse({ ...errorsInternal });
          setErrorMessage(get(errorsArr, '0.message'));
        },
      });
    }
  };

  const initializeAddressState = () => {
    setIsSubmitted(false);
    setIsVerified(false);
    setIsVerifyButtonClicked(false);
    setIsAddressPopupVisible(false);
  };

  const handleInputChange = (event) => {
    const { validator: { onChangeHandler } } = props;
    switch (event.target.name) {
      case 'purpose':
        if (get(values, 'purpose') === 'debtconsolidation') {
          initializeAddressState();
        }
        onChangeHandler(event.target.name, event.target.value);
        break;
      case 'applicant.address.state':
        initializeAddressState();
        onChangeHandler(event.target.name, event.target.value);
        break;
      case 'applicant.firstName':
      case 'applicant.lastName':
      case 'financials.stated.employerName':
        onChangeHandler(event.target.name, (event.target.value).replace(/[^a-zA-Z `'-]/g, ''));
        break;
      case 'applicant.address.zipcode':
        initializeAddressState();
        onChangeHandler(event.target.name, event.target.value);
        break;
      case 'applicant.address.address':
      case 'applicant.address.city':
        initializeAddressState();
        onChangeHandler(event.target.name, (event.target.value).replace(/[^a-zA-Z0-9- ']/g, ''));
        break;
      case 'financials.stated.grossMonthlyIncome':
        onChangeHandler(event.target.name, event.target.value);
        setGrossMonthlyIncomeDescription(null);
        break;
      default:
        onChangeHandler(event.target.name, event.target.value);
    }
  };

  const handleKeyUp = (event) => {
    const { validator: { onChangeHandler } } = props;
    if (event.keyCode === 8 || event.keyCode === 46) {
      onChangeHandler(event.target.name, event.target.value);
    }
  };

  const handleCheckboxChange = (name, value) => {
    const { validator: { onChangeHandler } } = props;
    onChangeHandler(name, value);
  };

  const handleSliderChange = (value) => {
    const { validator: { onChangeHandler } } = props;
    onChangeHandler('requestedAmount', value);
  };

  const handleOnBlur = (event) => {
    const { validator: { onChangeHandler } } = props;
    onChangeHandler(event.target.name, formatCurrency(floatUnmask(event.target.value || '0'), 2));
  };

  const handleSetValues = (e) => {
    e.preventDefault();
    const { validator: { setValues } } = props;

    const selectedOption = testSubjects.applications[selectedTestSubject].value;
    const formValues = {
      ...testSubjects.default,
      applicant: {
        ...testSubjects.default.applicant,
        ...selectedOption.applicant,
        iovationBlackBox,
      },
      submittedDate: getCurrentDate(),
    };

    setValues(formValues);
  };

  const verifySmartyStreet = ({ address, city, state, zipcode }) => {
    const { validator: { setValues } } = props;

    setIsAddressLoading(true);
    setIsVerifyButtonClicked(true);

    verifyAddress({ zipcode, address, city, state }).then((res) => {
      if (res) {
        if (get(res, 'metadata.recordType') === 'P') {
          setIsAddressPopupVisible(true);
          setIsAddressLoading(false);
          setIsVerified(false);
          setPopupType(3);
          setIsFirstLoad(false);
        } else {
          const addressData = {
            ...values,
            applicant: {
              ...values.applicant,
              address: {
                ...values.applicant.address,
                address: res.deliveryLine1,
                city: get(res, 'components.cityName'),
                state: get(res, 'components.state'),
                zipcode: `${res.deliveryPointBarcode}`.slice(0, 5),
              },
            },
          };
          setIsVerified(true);
          setIsAddressLoading(false);
          setIsFirstLoad(false);
          setPrevaddress({ address, city, state, zipcode });
          setValues(addressData);
        }
      } else {
        setIsAddressPopupVisible(true);
        setIsAddressLoading(false);
        setIsFirstLoad(false);
        setIsVerified(false);
        setPopupType(2);
      }
    }).catch(() => {
      setIsAddressLoading(false);
      setPopupType(1);
      setIsFirstLoad(false);
      setIsVerified(false);
    });
  };

  const handleBlur = (event) => {
    event.preventDefault();

    const { validator: { onChangeHandler } } = props;
    switch (event.target.name) {
      case 'applicant.address.state':
        if (unSupportedStates.indexOf(event.target.value) !== -1 && (
          event.target.value !== 'NY'
          || get(values, 'purpose') !== 'debtconsolidation'
        )) {
          setIsAddressPopupVisible(true);
          setIsAddressLoading(false);
          setPopupType(4);
          setIsVerified(false);
        } else if (isFirstLoad && get(values, 'applicant.address.city') && get(values, 'applicant.address.address')) {
          verifySmartyStreet({
            zipcode: get(values, 'applicant.address.zipcode'),
            address: get(values, 'applicant.address.address'),
            city: get(values, 'applicant.address.city'),
            state: event.target.value,
          });
        }
        break;
      case 'applicant.address.zipcode':
        if (isFirstLoad && get(values, 'applicant.address.city') && get(values, 'applicant.address.state') && get(values, 'applicant.address.address')) {
          verifySmartyStreet({
            zipcode: event.target.value,
            address: get(values, 'applicant.address.address'),
            city: get(values, 'applicant.address.city'),
            state: get(values, 'applicant.address.state'),
          });
        }
        onChangeHandler(event.target.name, toTitleCase(event.target.value));
        break;
      case 'applicant.address.address':
        if (isFirstLoad && get(values, 'applicant.address.city') && get(values, 'applicant.address.state')) {
          verifySmartyStreet({
            zipcode: get(values, 'applicant.address.zipcode'),
            address: toTitleCase(event.target.value),
            city: get(values, 'applicant.address.city'),
            state: get(values, 'applicant.address.state'),
          });
        }
        onChangeHandler(event.target.name, toTitleCase(event.target.value));
        break;
      case 'applicant.address.city':
        if (isFirstLoad && get(values, 'applicant.address.address') && get(values, 'applicant.address.state')) {
          verifySmartyStreet({
            zipcode: get(values, 'applicant.address.zipcode'),
            address: get(values, 'applicant.address.address'),
            city: toTitleCase(event.target.value),
            state: get(values, 'applicant.address.state'),
          });
        }
        onChangeHandler(event.target.name, toTitleCase(event.target.value));
        break;
      case 'applicant.email':
        onChangeHandler(event.target.name, event.target.value.toLowerCase());
        break;
      case 'financials.stated.grossMonthlyIncome':
        if (event.target.value === '') {
          setGrossMonthlyIncomeDescription('The input field is required');
        } else if ((Number(unmask(event.target.value)) > 41600 || Number(unmask(event.target.value)) < 1)) {
          setGrossMonthlyIncomeDescription('The amount you entered must be your gross MONTHLY income.');
        }
        onChangeHandler(event.target.name, event.target.value ? formatCurrency(floatUnmask(event.target.value), 2) : '');
        break;
      case 'financials.stated.monthlyRentOrMortage':
        onChangeHandler(event.target.name, event.target.value ? formatCurrency(floatUnmask(event.target.value), 2) : '');
        break;
      default:
        onChangeHandler(event.target.name, toTitleCase(event.target.value));
    }
  };

  const handleAbort = () => {
    setIsAddressPopupVisible(false);
  };

  const handleVerifyAddressClick = (e) => {
    e.preventDefault();

    if (unSupportedStates.indexOf(get(values, 'applicant.address.state')) !== -1 && (
      get(values, 'applicant.address.state') !== 'NY'
      || get(values, 'purpose') !== 'debtconsolidation'
    )) {
      setIsAddressPopupVisible(true);
      setIsAddressLoading(false);
      setPopupType(4);
      setIsVerified(false);
    } else if (!get(values, 'applicant.address.address')) {
      setIsAddressPopupVisible(true);
      setPopupType(1);
      setIsVerifyButtonClicked(true);
    } else {
      verifySmartyStreet({
        address: get(values, 'applicant.address.address'),
        city: get(values, 'applicant.address.city'),
        state: get(values, 'applicant.address.state'),
        zipcode: get(values, 'applicant.address.zipcode'),
      });
    }
  };

  const handleUndo = (e) => {
    e.preventDefault();

    const { validator: { setValues } } = props;
    const addressData = {
      ...values,
      address: get(prevaddress, 'address'),
      city: get(prevaddress, 'city'),
      state: get(prevaddress, 'state'),
      zipcode: get(prevaddress, 'zipcode'),
    };

    setIsVerified(false);
    setValues(addressData);
  };

  const togglePopup = (e) => {
    e.preventDefault();
    setIsPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    if (!values.purpose) {
      values.purpose = 'debtconsolidation';
    }

    let isFormValidInternal = isValid && isFormValidFromService;
    const formErrorsInternal = {
      ...errorResponse,
      ...errors,
    };

    if (!get(formErrorsInternal, 'applicant.address.address') && isSubmitted && !isVerified && appConfig.smartyStreetEnforce) {
      formErrorsInternal['applicant.address.address'] = isVerifyButtonClicked ? 'Address is not valid, please enter valid address' : 'Please click verify Address';
      isFormValidInternal = false;
    }

    if (form && form.current && !isFormValidInternal && isSubmitted) {
      const errs = Object.keys(formErrorsInternal);
      form.current[errs[0]] && form.current[errs[0]].focus();
      setIsSubmitted(false);
    }

    setFormErrors(formErrorsInternal);

    const employmentStatus = get(values, 'financials.stated.employmentStatus');
    setIsEmploymentInfoRequired(!(employmentStatus === 'retired' || employmentStatus === 'unemployed' || employmentStatus === 'student'));
  }, [isValid, isFormValidFromService, values, errorResponse, errors, isVerified, isVerifyButtonClicked]);

  return (
    iovationBlackBox ? (
      <form className={cn(className, 'borrower-application')} onSubmit={handleSubmitForm.bind(null, values)} ref={form}>
        {
          appConfig.isSetValueVisible && testSubjects && testSubjects.applications && testSubjects.applications.length > 0
            && (
            <Container className="setValuesWrapper">
              <div>
                <Select
                  name="testSubject"
                  data={testSubjects.applications.map((el, i) => ({ title: el.title, value: i }))}
                  value={selectedTestSubject}
                  onChange={(e) => setSelectedTestSubject(e.target.value)}
                  label="Set values"
                  placeHolder="Set values"
                  hasDefault={!selectedTestSubject}
                  style={{ width: 'auto' }}
                  isErrorVisible={false}
                />

                <Button
                  className={cn('large', 'w-100', 'btnSetValues')}
                  onClick={handleSetValues}
                  color="primary"
                >
                  SET VALUES
                </Button>
              </div>
              {isDupeRuleEnabled && <span>DUPE RULES ENABLED</span>}
            </Container>
            )
        }
        <Container>
          <Row>
            <Col xs={12} className="mt-md-3 mb-2 mb-md-1">
              <h1 className={cn('mb-1', 'checkRateTitle')}>
                Check Your Rate
                <span>*</span>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <LoanAmountRequested
                amount={values.requestedAmount}
                onChange={handleSliderChange}
                hasError={!!formErrors.requestedAmount}
                errorMessage={formErrors.requestedAmount}
                style={{ marginTop: '40px', marginBottom: 0 }}
                onBlur={handleOnBlur}
              />

              {!purpose && (
                <Row>
                  <Col xs={12}>
                    <h3 className="border-bottom pb-2 mb-3">Product/Service Information</h3>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="dropdown-toggle mb-0 pb-0">
                      <Select
                        name="purpose"
                        className={isFuneral ? 'funeral-full-opacity' : ''}
                        data={businessTypeList}
                        value={isFuneral ? 'funeral' : values.purpose}
                        onChange={isFuneral ? () => {} : handleInputChange}
                        label="Product/Service Type"
                        isBadgeVisible={!isFuneral}
                        isDisabled={isFuneral}
                        isRequired
                        hasError={!!formErrors.purpose}
                        errorMessage={formErrors.purpose}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <Row className="mb-4">
                <Col xs={12}>
                  <h3 className="border-bottom pb-2 mb-3">Personal Information</h3>
                </Col>
                <Col sm={6}>
                  <p className="mb-3">Please provide the legal name and personal identity information for the primary borrower.</p>
                </Col>
                <Col sm={6}>
                  <p className="mb-3">Enter the address of the primary borrower above. Must be a valid street address, no P.O. Boxes.</p>
                </Col>
                <Col sm={6}>
                  <Input
                    label="First Name"
                    name="applicant.firstName"
                    value={values.applicant && values.applicant.firstName}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    isRequired
                    hasError={!!formErrors['applicant.firstName']}
                    errorMessage={formErrors['applicant.firstName']}
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    label="Last Name"
                    name="applicant.lastName"
                    value={values.applicant && values.applicant.lastName}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    isRequired
                    hasError={!!formErrors['applicant.lastName']}
                    errorMessage={formErrors['applicant.lastName']}
                  />
                </Col>
                <Col xs={12} className="positionRelative">
                  <Input
                    label="Street Address"
                    name="applicant.address.address"
                    value={values.applicant && values.applicant.address && values.applicant.address.address}
                    onChange={handleInputChange}
                    isRequired
                    hasError={!!formErrors['applicant.address.address']}
                    errorMessage={formErrors['applicant.address.address']}
                    onBlur={handleBlur}
                    className="streetAddress"
                  />
                  <VerifyButton
                    onClick={isVerified ? handleUndo : handleVerifyAddressClick}
                    isVerified={isVerified}
                    isLoading={isAddressLoading}
                  />
                  { isAddressPopupVisible
                    && (
                    <Popup
                      handleAbort={handleAbort}
                      type={popupType}
                      data={{
                        address: get(values, 'applicant.address.address'),
                        city: get(values, 'applicant.address.city'),
                        state: get(values, 'applicant.address.state'),
                        zipcode: get(values, 'applicant.address.zipcode'),
                      }}
                    />
                    )}
                </Col>
                <Col md={6}>
                  <Input
                    label="City"
                    name="applicant.address.city"
                    value={values.applicant && values.applicant.address && values.applicant.address.city}
                    onChange={handleInputChange}
                    isRequired
                    hasError={!!formErrors['applicant.address.city']}
                    errorMessage={formErrors['applicant.address.city']}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Row>
                    <Col sm={7}>
                      <FormGroup className="dropdown-toggle mb-0 pb-0">
                        <Select
                          name="applicant.address.state"
                          data={states}
                          value={values.applicant && values.applicant.address && values.applicant.address.state}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          label="State"
                          isRequired
                          hasError={!!formErrors['applicant.address.state']}
                          errorMessage={formErrors['applicant.address.state']}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={5}>
                      <Input
                        label="Zip"
                        isMasked={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                        name="applicant.address.zipcode"
                        placeHolder="_____"
                        value={values.applicant && values.applicant.address && values.applicant.address.zipcode}
                        onChange={handleInputChange}
                        isRequired
                        hasError={!!formErrors['applicant.address.zipcode']}
                        errorMessage={formErrors['applicant.address.zipcode']}
                      />
                    </Col>
                  </Row>
                  <Row id="ResidentAlert" className="hide">
                    <Col sm={12}>
                      <div className="alert callout">
                        <p>
                          We currently do not accept loan applications for residents of the state of
                          {' '}
                          <span className="state">state</span>
                          .
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={6}>
                  <Input
                    label="Date of Birth"
                    name="applicant.dateOfBirth"
                    isMasked={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    placeHolder="__/__/____"
                    value={values.applicant && values.applicant.dateOfBirth}
                    onChange={handleInputChange}
                    isRequired
                    hasError={!!formErrors['applicant.dateOfBirth']}
                    errorMessage={formErrors['applicant.dateOfBirth']}
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    label="Social Security #"
                    name="applicant.ssn"
                    // eslint-disable-next-line
                    isMasked={[/\d/,/\d/,/\d/, '-', /\d/,/\d/, '-',/\d/,/\d/,/\d/,/\d/]}
                    placeHolder="___-__-____"
                    value={values.applicant && values.applicant.ssn}
                    onChange={handleInputChange}
                    isRequired
                    hasError={!!formErrors['applicant.ssn']}
                    errorMessage={formErrors['applicant.ssn']}
                  />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col xs={12}>
                  <h3 className="border-bottom pb-2 mb-3">Contact Information</h3>
                </Col>
                <Col xs={12}>
                  <p className="mb-3">Please provide your email and mobile phone information to receive a loan status update.</p>
                </Col>
                <Col sm={6}>
                  <Input
                    label="Email Address"
                    name="applicant.email"
                    isMasked={emailMask}
                    placeHolder="Email address"
                    value={values.applicant && values.applicant.email}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyUp}
                    isRequired
                    hasError={!!formErrors['applicant.email']}
                    errorMessage={formErrors['applicant.email']}
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    label="Mobile Phone"
                    name="applicant.phoneNumber.number"
                    placeHolder="(___) ___-____"
                    isMasked={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    value={values.applicant && values.applicant.phoneNumber && values.applicant.phoneNumber.number}
                    onChange={handleInputChange}
                    isRequired
                    hasError={!!formErrors['applicant.phoneNumber.number']}
                    errorMessage={formErrors['applicant.phoneNumber.number']}
                  />
                </Col>
                <Col xs={12}>
                  <Checkbox
                    className="mb-0"
                    label={[(
                      <div>
                        I consent to receive calls and texts for any purpose, including marketing, under these&nbsp;
                        <ButtonLink
                          onClick={togglePopup}
                          className="viewDetailCheckbox"
                        >
                          terms
                        </ButtonLink>
                        . You may opt out by replying “STOP”. Message and data rates may apply.
                      </div>
                    )]}
                    name="consentMarketing"
                    onChange={handleCheckboxChange.bind(null, 'consentMarketing', !values.consentMarketing)}
                    isChecked={values.consentMarketing}
                    value="on"
                    id="consentMarketing"
                    type="checkbox"
                    isRequired
                    errorMessage={errors.consentMarketing}
                  />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col xs={12}>
                  <h3 className="border-bottom pb-2 mb-3">Financials &amp; Employment</h3>
                </Col>
                <Col xs={12}>
                  <p className="mb-3">Your total verifiable gross income might include your salary, retirement income or other sources of income. You don&apos;t have to include alimony, child support, or separate maintenance income unless you want it considered as a basis for repayment.</p>
                  <p>
                    <strong>
                      All stated income and/or employment
                      {' '}
                      <u>will require verification</u>
                      {' '}
                      prior to the approval of your loan.
                    </strong>
                  </p>
                </Col>
                <Col sm={12} md={6}>
                  <Input
                    label="Gross Monthly Income"
                    name="financials.stated.grossMonthlyIncome"
                    value={values.financials && values.financials.stated && `${values.financials.stated.grossMonthlyIncome || ''}`}
                    onChange={handleInputChange}
                    isRequired
                    hasError={!!formErrors['financials.stated.grossMonthlyIncome'] || !!grossMonthlyIncomeDescription}
                    isMasked={currencyMask}
                    errorMessage={formErrors['financials.stated.grossMonthlyIncome'] || grossMonthlyIncomeDescription}
                    onBlur={handleBlur}
                    height={30}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <FormGroup className="dropdown-toggle mb-0 pb-0">
                    <Select
                      name="financials.stated.employmentStatus"
                      data={
                        employmentStatusData
                          ? Object.keys(employmentStatusData).map((key) => ({
                            value: get(employmentStatusData, `${key}.code`),
                            title: get(employmentStatusData, `${key}.label`),
                          }))
                          : []
                      }
                      value={get(values, 'financials.stated.employmentStatus') || ''}
                      onChange={handleInputChange}
                      label="Employment Status"
                      isRequired
                      hasError={!!formErrors['financials.stated.employmentStatus']}
                      errorMessage={formErrors['financials.stated.employmentStatus']}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6}>
                  <Input
                    label="Employer Name"
                    name="financials.stated.employerName"
                    value={values.financials && values.financials.stated && values.financials.stated.employerName}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <FormGroup className="dropdown-toggle mb-0 pb-0">
                    <Select
                      name="financials.stated.employmentYears"
                      data={
                        employmentYearsData
                          ? Object.keys(employmentYearsData).map((key) => ({
                            value: get(employmentYearsData, `${key}.code`),
                            title: get(employmentYearsData, `${key}.label`),
                          })) : []
                      }
                      value={get(values, 'financials.stated.employmentYears') || ''}
                      onChange={handleInputChange}
                      label="Years with Current Employer"
                      isDisabled={!isEmploymentInfoRequired}
                      hasError={!!formErrors['financials.stated.employmentYears']}
                      errorMessage={formErrors['financials.stated.employmentYears']}
                    />
                  </FormGroup>
                </Col>
                {values.purpose && values.purpose === 'funeral'
                && (
                <Col sm={12} md={6}>
                  <Input
                    label="Decedent's Name"
                    name="ClientId"
                    value={values.ClientId}
                    onChange={handleInputChange}
                    isRequired={get(values, 'purpose') === 'funeral'}
                    hasError={!!formErrors.ClientId}
                    errorMessage={formErrors.ClientId}
                  />
                </Col>
                )}
              </Row>

              <Row className="mb-4">
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="mb-2">
                      <h5 className="mb-1">By checking the box below I agree to the following:</h5>
                      <PopoverCheckbox
                        className="text-primary text-link"
                        label={consentLabel}
                        name="allConsent"
                        onChange={handleCheckboxChange.bind(null, 'allConsent')}
                        isChecked={values.allConsent}
                        id="allConsent"
                        errorMessage={formErrors.allConsent}
                      />
                      { false
                        && (
                        <PopoverCheckbox
                          label={['I consent to receive the following calls and text messages']}
                          name="consentToReceiveMessages"
                          onChange={handleCheckboxChange.bind(null, 'consentToReceiveMessages')}
                          isChecked={values.consentToReceiveMessages}
                          id="consentToReceiveMessages"
                          errorMessage={formErrors.consentToReceiveMessages}
                        >
                          By providing a telephone number for a landline, cellular phone or other wireless device, and clicking &#34;CONTINUE TO NEXT STEP&#34; or &#34;CHECK MY RATE&#34;, you are expressly consenting to receiving communications at that number, including, but not limited to, prerecorded voice messages and telephone calls made by an auto-dialer or by any representative from LendingUSA, Lender, third party servicer or other affiliated third parties. This express consent applies to each such telephone number that you provide to LendingUSA, Lender, third party servicer or other Third Parties, either now or in the future. These telephone calls and messages may incur message, data and access fees from your telephone provider. Consent is not a condition of applying for a loan. You understand that you may opt out of this authorization by providing written notice to LendingUSA at the following address: 15303 Ventura Blvd., Ste 850, Sherman Oaks, CA 91403.
                          <br />
                          <br />
                          Furthermore, by agreeing to this consent box and clicking &#34;CONTINUE TO NEXT STEP&#34; or &#34;CHECK MY RATE&#34; you warrant and represent to LendingUSA that you are the named Applicant listed on this application and you authorize LendingUSA, its Lender(s), affiliated third parties and service providers to send texts with follow up messages regarding this application, promotional loan product/services, and other marketing or information about LendingUSA, and/or its Lender(s) to the telephone number provided in this application using an auto-dialer, even if that number is on a federal or state do-not-call list. Consent is not a condition of applying for a loan. Your agreeing to this consent box and clicking &#34;CONTINUE TO NEXT STEP&#34; or &#34;CHECK MY RATE&#34; is your electronic signature agreeing to receive text messages. Call 800-994-6177 for a free paper copy of these terms. Reply HELP for help; Reply STOP to withdraw consent. Msg. and data rates may apply.
                        </PopoverCheckbox>
                        )}
                    </Col>
                    <Col xs={12}>
                      <h5 className="mb-1">Important information about procedures for opening a new account</h5>
                      <FormGroup className="pb-0">
                        <div className="no-resize" style={{ height: '80px', overflowY: 'scroll', backgroundColor: '#f9f9f8', padding: '5px 15px', border: '2px solid rgba( 0, 0, 0, 0.1)', color: '#797a93', borderRadius: '6px' }}>
                          <p>To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.</p>
                          <p>What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver&apos;s license or other identifying documents.</p>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <div className="border-top pt-3 mt-1">
                        <Button
                          className={cn('large w-100 submit-button', isLoading ? '' : 'arrow')}
                          onClick={handleSubmitForm.bind(null, values)}
                          isDisabled={!(values.consentElectronicCommunication && values.consentToTermsAndConditions && values.consentToThirdParty)}
                          isLoading={isLoading}
                          color="primary"
                          size="lg"
                        >
                          Check my rate
                          {' '}
                          <img src="/icons/arrow-right-lg.svg" alt="" style={{ marginLeft: 12, width: 16 }} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <p className="errorSection">{errorMessage}</p>
              </Row>
            </Col>
            <Sidebar bottomBoundary={2480} />
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <p style={{ fontSize: '.8rem' }}>
                *You may be reviewed for a loan through LendingUSA, or for a loan through a third-party, or for both. Loans through LendingUSA (i) are made by its
                {' '}
                <a href="https://lendingusa.com/lending-partners/" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">lending partners</a>
                , (ii) have varying minimum and maximum financed amounts based on the State, term and/or loan purpose, (iii) are not available in all States, (iv) may contain an origination fee of up to 8% which is included in the principal loan amount, and (v) may have an APR of up to 29.99%.
              </p>
            </Col>
          </Row>
        </Container>
        {/* <!-- Modal --> */}
        <Modal
          className="termsModal"
          isOpen={isPopupVisible}
          toggle={togglePopup}
          backdrop="static"
          centered
          size="md"
          fade={false} // Removed animation which is throwing an error due to outdated reactstrap
        >
          <ModalHeader toggle={togglePopup}>
            Terms
          </ModalHeader>
          <ModalBody>
            <p>
              By providing the telephone number above and clicking &ldquo;CHECK MY RATE&rdquo;, you confirm you are the owner of the phone number(s), or that the owner authorized you to provide the phone number(s), or that you are the regular user of this phone number(s). You are providing express written consent via your electronic signature to receive communications from our
              {' '}
              <a href="https://lendingusa.com/lending-partners/" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">lending partners</a>
              , LendingUSA, their agents, representatives, assignees or anyone calling on their behalf, at the telephone number(s) provided for any lawful purpose, even if that number is on a federal or state do-not-call list. The scope of this consent includes, but is not limited to, receiving live operator calls, calls and texts via an automatic telephone dialing system (auto-dialer), prerecorded and artificial voice messages, text/SMS messages or other means. These telephone calls and text messages may incur message, data and access fees from your telephone provider. Consent is not a condition to obtain a loan.
              <br />
              <br />
              You understand that you can request a paper copy of these terms or may withdraw your consent at any time by:

              <ul>
                <li>mailing your written request to LendingUSA c/o Compliance Department at 15303 Ventura Blvd., Suite 850, Sherman Oaks, CA 91403;</li>
                <li>
                  by e-mailing
                  {' '}
                  <a href="mailto:customercare@lendingusa.com">customercare@lendingusa.com</a>
                  ; or
                </li>
                <li>by calling LendingUSA at (800) 994-6177.</li>
              </ul>
              You may also reply “STOP” to opt out of receiving autodialed text messages only.
            </p>
          </ModalBody>
        </Modal>
        {/* <!-- End Modal --> */}
      </form>
    ) : null
  );
};

Application.propTypes = {
  className: PropTypes.string,
  validator: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  checkinAction: PropTypes.func.isRequired,
  fetchEmploymentYears: PropTypes.func.isRequired,
  fetchEmploymentStatus: PropTypes.func.isRequired,
  ipAddress: PropTypes.string.isRequired,
  getAffDetails: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  getTestValuesData: PropTypes.func.isRequired,
  checkDuplication: PropTypes.func.isRequired,
};

Application.defaultProps = {
  className: '',
};

export default compose(
  Validator(schema),
  connect(
    null,
    {
      checkinAction,
      fetchRentOrOwn,
      fetchEmploymentYears,
      fetchEmploymentStatus,
      getAffDetails,
      getTestValuesData,
      checkDuplication,
    }
  )
)(Application);
